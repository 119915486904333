<template>
  <div>
    <t-modal
      :name="mid"
      variant="subscription"
      :hideCloseButton="true"
      :disableBodyScroll="true"
    >
      <template v-slot:header>
        <div class="text-gray-800 feature-item disable-border bg-yellow-custom">
          <div class="flex items-center left" style="font-size:35px">
            <div class="font-bold capitalize">{{ item.plan_name }}</div>

            <div
              v-if="$user.is_superadmin"
              @click="edit(item)"
              class="flex items-center mt-1 ml-2 bg-green-600 rounded cursor-pointer hover:bg-green-500"
              style="height: 1.75rem; width: 1.75rem"
            >
              <svg
                class="ml-px w-6 h-5 text-gray-100"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="flex flex-col right disable-border-for-right-item">
            <div class="font-bold" style="font-size: 20px">
              {{ symbol }}{{ item.price }}
            </div>
            <div class="mt-2 normal-case" style="font-size: 15px">
              {{ getBillingType }}
            </div>
          </div>
        </div>
      </template>

      <section>
        <div class="feature-item">
          <div class="text-sm left">Currency</div>
          <div class="text-sm right">{{ getCurrencyType }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Language</div>
          <div class="text-sm right">{{ getLanguageType }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Geofence</div>
          <div class="text-sm right">{{ getGeofenceType }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Number of Vehicles</div>
          <div class="text-sm right">{{ getVehicleCount }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Number of Fleets</div>
          <div class="text-sm right">{{ getFleetCount }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Number of Promo Codes</div>
          <div class="text-sm right">{{ getFleetCount }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Number of Org User Accounts</div>
          <div class="text-sm right">{{ getAccountCount }}</div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">End Ride Photo</div>
          <div class="text-sm right" v-html="getEndRidePhoto"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Group Trips Enabled</div>
          <div class="text-sm right" v-html="getGroupTripEnableStatus"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Vehicle Rental Enabled</div>
          <div
            class="text-sm right"
            v-html="getVehicleRentalEnableStatus"
          ></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Access to Operator App</div>
          <div class="text-sm right" v-html="getAccessToOperatorApp"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Point Management</div>
          <div class="text-sm right" v-html="getPointManagement"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Pass Management</div>
          <div class="text-sm right" v-html="getPassManagement"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Ticket Management</div>
          <div class="text-sm right" v-html="getTicketManagement"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Automatic Fleet Assignment</div>
          <div class="text-sm right" v-html="getAutomaticFleetAssignment"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Service Area Swapping</div>
          <div
            class="text-sm right"
            v-html="getServiceAreaSwappingFeature"
          ></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">File Export</div>
          <div class="text-sm right" v-html="getFileExportFeature"></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Use Otoride Default Email Credential</div>
          <div
            class="text-sm right"
            v-html="getOtorideDefaultEmailCredentialFeature"
          ></div>
        </div>
        <div class="feature-item">
          <div class="text-sm left">Data Sharing</div>
          <div class="text-sm right">{{ getDataSharing }}</div>
        </div>
      </section>
    </t-modal>
  </div>
</template>

<script>
// SubscriptionConfig
import { SubscriptionConfig } from '@/config/SubscriptionConfig'

import { EventBus } from '@/utils/EventBus'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      mid: SubscriptionConfig.events.viewingData,
      symbol: '$',
      item: {},
      tick: `<span class="text-sm font-bold text-green-600">✓</span>`,
      cross: `<span class="font-bold text-gray-500">✗</span>`,
    }
  },
  methods: {
    edit: function(item) {
      this.$modal.hide(SubscriptionConfig.events.viewingData)
      EventBus.$emit(SubscriptionConfig.events.editingData, item)
      dispatchEvent(new Event(SubscriptionConfig.events.sorToggle))
    },
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    getCurrencyType: function() {
      return this.item.multi_currency_enabled
        ? 'Multi Currency'
        : 'Single Currency'
    },
    getLanguageType: function() {
      return this.item.multi_language_enabled
        ? 'Multi Language'
        : 'Single Language'
    },
    getGeofenceType: function() {
      return this.item.multi_geofence_enabled
        ? 'Multi Geofence'
        : 'Single Geofence'
    },
    getVehicleCount: function() {
      return this.item.num_of_vehicles
    },
    getFleetCount: function() {
      return this.item.num_of_fleets
    },
    getPromoCount: function() {
      return this.item.num_of_promo_codes
    },
    getAccountCount: function() {
      return this.item.num_of_org_user_accounts
    },
    getEndRidePhoto: function() {
      return this.item.end_ride_photo_enabled ? this.tick : this.cross
    },
    getAccessToOperatorApp: function() {
      return this.item.operator_app_enabled ? this.tick : this.cross
    },
    getGroupTripEnableStatus: function() {
      return this.item.group_trip_enabled ? this.tick : this.cross
    },
    getVehicleRentalEnableStatus: function() {
      return this.item.rental_enabled ? this.tick : this.cross
    },
    getPointManagement: function() {
      return this.item.point_management_enabled ? this.tick : this.cross
    },
    getPassManagement: function() {
      return this.item.pass_management_enabled ? this.tick : this.cross
    },

    getTicketManagement: function() {
      return this.item.ticket_management_enabled ? this.tick : this.cross
    },
    getAutomaticFleetAssignment: function() {
      return this.item.is_automatic_fleet_assignment_enabled
        ? this.tick
        : this.cross
    },
    getServiceAreaSwappingFeature: function() {
      return this.item.is_automatic_fleet_assignment_enabled
        ? this.tick
        : this.cross
    },
    getFileExportFeature: function() {
      return this.item.is_file_export_enabled ? this.tick : this.cross
    },
    getOtorideDefaultEmailCredentialFeature: function() {
      return this.item.should_use_otoride_email_credentials
        ? this.tick
        : this.cross
    },
    getBillingType: function() {
      return '/' + this.item.billing_type.split('/')[1]
    },
    getDataSharing: function() {
      var data = []
      if (this.item.reporting_categories_mds === true) {
        data.push('MDS')
      }
      if (this.item.reporting_categories_gbfs === true) {
        data.push('GBFS')
      }
      if (!data.length) return '--'
      return data.join(', ')
    },
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    EventBus.$on(SubscriptionConfig.events.viewingData, (item) => {
      console.log({ item })
      this.item = item
    })
  },
}
</script>

<style lang="scss" scoped>
$light-gray: #e2e8f0;
.bg-yellow-custom {
  background-color: #ffeb00;
}
.feature-item {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray;
  padding: 0; // 15px 0?
  &.disable-border {
    border-bottom: 0;
  }

  & .spacing {
    padding: 2.5rem;
  }
  & .left {
    width: 60%;
    // padding-right: 0.5rem;
    font-size: 0.875rem;
    padding: 0.875rem;
    font-weight: 500;
  }
  & .right {
    width: 40%;
    // padding-left: 0.5rem;
    padding: 0.875rem;
    border-left: 1px solid $light-gray;
    font-weight: 500;
  }
  & .disable-border-for-right-item {
    border-left: 0;
  }
}
.max-h-80vh {
  max-height: 80vh;
}
</style>
